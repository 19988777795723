import React from 'react';

import cx from 'classnames';
import Button from 'src/components/button/button';
import Icon, { IconProps } from 'src/components/icon/icon';
import { BodyLarge, H3 } from 'src/components/typography/typography';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './empty-state.module.less';

interface EmptyStateProps extends SpacingProps {
  title: string;
  subtitle?: string | null;
  icon: IconProps['name'];
  action?: {
    text: string;
    onClick: () => void;
  };
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  icon,
  spacing = { margins: { lg: 'all' } },
  subtitle,
  action,
  children,
}) => {
  const spacingClass = useSpacing(spacing);
  return (
    <div className={cx(styles.emptyState, spacingClass)}>
      <Icon name={icon} className={styles.icon} />
      <H3 noMargin>{title}</H3>
      {subtitle && (
        <BodyLarge light spacing={{ margins: { sm: 'top' } }}>
          {subtitle}
        </BodyLarge>
      )}
      {action && (
        <Button onClick={action.onClick} size="sm" spacing={{ margins: { sm: 'top' } }} linkStyle>
          {action.text}
        </Button>
      )}
      {children}
    </div>
  );
};

export default EmptyState;
